import React from "react";
import "../App.css";
import "./HeroSection.css";
import Picture from "./Picture";

function HeroSection() {
  // const history = useHistory();
  return (
    <>
      <Picture />
    </>
  );
}

export default HeroSection;
